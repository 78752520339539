// import 'bootstrap/js/dist/alert';
import "bootstrap/js/dist/button";
// import 'bootstrap/js/dist/carousel';
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
import "bootstrap/js/dist/offcanvas";
// import 'bootstrap/js/dist/popover';
import "bootstrap/js/dist/scrollspy";
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

document.addEventListener("DOMContentLoaded", () => {
  body = document.querySelector("body");
  navbarToggler = document.querySelector('.navbar-toggler');
  navbarToggler.addEventListener("click", function () {
    body.classList.toggle('menu-open');
  });

  // Helper to update the href of #speedbump-link
  const updateSpeedbumpLink = (newHref) => {
    const speedbumpLink = document.querySelector("#speedbump-link");
    if (speedbumpLink) {
      speedbumpLink.setAttribute("href", newHref);
    }
  };

  // Helper to show modal
  const showModal = (element) => {
    if (element) {
      const modal = new Modal(element);
      modal.show();
    } else {
      console.warn("Modal element not found in DOM");
    }
  };

  const links = document.querySelectorAll(
    "a:not(#speedbump-link), button:not(#speedbump-link)"
  );
  const speedbumpElement = document.getElementById("speedbump");

  // Reset speedbump link when modal is closed
  if (speedbumpElement) {
    speedbumpElement.addEventListener("hidden.bs.modal", () =>
      updateSpeedbumpLink("")
    );
  } else {
    console.warn("Modal element not found in DOM");
  }

  links.forEach((el) => {
    el.addEventListener("click", function (e) {
      const href = this.getAttribute("href") || this.getAttribute("data-href");

      if (!href || /^(mailto:|tel:|#)/.test(href)) {
        return;
      }

      const tempAnchor = document.createElement("a");
      tempAnchor.href = href;
      const currentDomain = window.location.hostname;

      if (tempAnchor.hostname !== "" && tempAnchor.hostname !== currentDomain) {
        e.preventDefault();
        updateSpeedbumpLink(href);
        showModal(speedbumpElement);
      }
    });
  });

  // Add class to paragraphs that start with a special character
  function hangIndent() {
    const specialChars = ["*", "†"];

    const paragraphs = document.querySelectorAll(".fine-print p");

    paragraphs.forEach((p) => {
      const firstChar = p.textContent.trim().charAt(0);

      if (specialChars.includes(firstChar)) {
        p.classList.add("hanging-punctuation");
      }
    });
  }

  hangIndent();

  // Footer menu cookie preference center toggle
  // Make sure that the link set in Drupal footer menu is "#cookie-info"
  const cookieToggleLink = document.querySelector(
    "footer [href='#cookie-info']"
  );
  if (cookieToggleLink) {
    cookieToggleLink.addEventListener("click", function (event) {
      event.preventDefault();
      try {
        OneTrust.ToggleInfoDisplay();
      }
    });
  }

  // VIDEO RESOURCE MODALS
  let featuredVideoSection = document.getElementById("featuredVideoModal");

  if (featuredVideoSection) {
    let featuredVideoModal = new Modal(featuredVideoSection);
    let featuredVideoIframe = document.getElementById("featuredVideoIframe");
    let videoUrl = featuredVideoIframe.getAttribute("src").toString().trim();
    console.log("Video URL: " + videoUrl);
    // Open modal when play button is clicked
    document
      .getElementById("featuredVideoModalTrigger")
      .addEventListener("click", function () {
        featuredVideoIframe.src = videoUrl; // Load default URL without timestamp
        featuredVideoModal.show();
      });
    // Chapter links to open modal and jump to timestamp
    document.querySelectorAll(".chapter-link").forEach(function (link) {
      link.addEventListener("click", function (e) {
        e.preventDefault();
        var timestamp = this.getAttribute("data-timestamp");
        console.log(timestamp);
        featuredVideoIframe.src = setVideoSource(videoUrl, timestamp); // Set video with timestamp
        featuredVideoModal.show();
      });
    });
    // Clear iframe on modal close to stop the video
    document
      .getElementById("featuredVideoModal")
      .addEventListener("hidden.bs.modal", function () {
        featuredVideoIframe.src = "";
      });
  }

  // Function to update iframe source with timestamp
  function setVideoSource(url, timestamp) {
    let newUrl;
    if (url.includes("youtube")) {
      // For YouTube, the timestamp is added with '?start='
      newUrl = url + "?start=" + timestamp;
    } else if (url.includes("vimeo")) {
      // For Vimeo, the timestamp is added with '#t='
      newUrl = url + "#t=" + timestamp + "s";
    }
    console.log(newUrl);
    return newUrl;
  }

  // Accordion "Read More" text toggle
  var accordions = document.querySelectorAll(".accordion-button");

  accordions.forEach(function (accordion) {
    accordion.addEventListener("click", function () {
      var isCollapsed = this.classList.contains("collapsed");
      var textSpan = this.querySelector(".read-more-text");
      console.log("accordion clicked");

      if (isCollapsed) {
        textSpan.textContent = "READ MORE";
      } else {
        textSpan.textContent = "READ LESS";
      }
    });
  });
});

function updateVideoSource() {
  const videoElement = document.getElementById("homeHeroBackgroundVideo");
  const videoSource = document.getElementById("videoSource");
  const screenWidth = window.innerWidth;

  if (videoElement) {
    let newPoster;
    let newSrc;

    // Set video source based on screen width
    if (screenWidth <= 767) {
      newPoster = "/sites/default/files/2024-10/home-hero-video-poster-768x1000.jpg";
      newSrc = "/sites/default/files/2024-10/12224700_FCBH_ONO_VELE_Website%20Animation%202024_768x1000_v03.mp4";
    } else if (screenWidth <= 992) {
      newPoster = "/sites/default/files/2024-10/home-hero-video-poster-992x800.jpg";
      newSrc = "/sites/default/files/2024-10/12224700_FCBH_ONO_VELE_Website%20Animation%202024_992x800_v03.mp4";
    } else if (screenWidth <= 1200) {
      newPoster = "/sites/default/files/2024-10/home-hero-video-poster-1200x800.jpg";
      newSrc = "/sites/default/files/2024-10/12224700_FCBH_ONO_VELE_Website%20Animation%202024_1200x800_v03.mp4";
    } else {
      newPoster = "/sites/default/files/2024-10/home-hero-video-poster-1920x1080.jpg";
      newSrc = "/sites/default/files/2024-11/12224700_FCBH_ONO_VELE_Website%20Animation%202024_1920x1080_alt_v04.mp4";
    }

    // Only change the source and poster if they're different
    if (videoSource.src !== newSrc || videoElement.poster !== newPoster) {
      videoSource.src = newSrc;
      videoElement.poster = newPoster;
      videoElement.load(); // Reloads the video with the new source
    }
  }
}

// Run the function initially and on window resize
window.addEventListener("resize", updateVideoSource);
window.addEventListener("load", updateVideoSource);

let originalTop;

function innerNavigationMenu() {
  const innerMenu = document.querySelector('.paragraph--type--inner-navigation-menu');
  const header = document.querySelector('header');

  if (!innerMenu || !header) {
    // console.error("Element not found");
    return;
  }

  let headerHeight = header.offsetHeight;

  if (!originalTop) {
    originalTop = innerMenu.getBoundingClientRect().top + window.scrollY;
  }

  let scrollPosition = window.scrollY;

  if (scrollPosition + headerHeight >= originalTop) {
    if (!innerMenu.classList.contains('fixed')) {
      innerMenu.classList.add('fixed');
      innerMenu.style.top = headerHeight + 'px';
    }
  } else {
    if (innerMenu.classList.contains('fixed')) {
      innerMenu.classList.remove('fixed');
      innerMenu.style.top = '';
    }
  }
}

window.addEventListener("scroll", innerNavigationMenu);
window.addEventListener("resize", innerNavigationMenu);

// IntersectionObserver for active class
const sections = document.querySelectorAll('section');
const links = document.querySelectorAll('.anchor-links a');

const observerOptions = {
  root: null,
  rootMargin: `-${document.querySelector('header').offsetHeight}px 0px -50% 0px`,
  threshold: 0
};

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    const id = entry.target.getAttribute('id');
    const link = document.querySelector(`.anchor-links a[href="#${id}"]`);

    if (link) {
      if (entry.isIntersecting) {
        // console.log(`Section ${id} is intersecting`);
        links.forEach(link => link.classList.remove('active'));
        link.classList.add('active');
      } else {
        link.classList.remove('active');
        // console.log(`Section ${id} is not intersecting`);
      }
    } else {
      // console.error(`Link not found for section with id: ${id}`);
    }
  });
}, observerOptions);

sections.forEach(section => {
  observer.observe(section);
});

